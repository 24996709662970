import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'


// Define the initial state
const initialState = {
    expenses: [],
    singleExpense: null,
    expensesData: [],
    loading: false,
    error: null,
};

export const addExpense = createAsyncThunk(
    'expenses/addExpense',
    async ({ date, number, client, typeOfPayment, comment, company_id, tableData }, { rejectWithValue }) => {
        try {
            const response = await api.post('/expense/expense', { date, number, client, typeOfPayment, comment, company_id, tableData   });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const getAllExpenses = createAsyncThunk(
    'expenses/getAllExpenses',
    async ({ company_id }, { rejectWithValue }) => {
        try {
            const response = await api.post('/expense/expense/all', { company_id });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const deleteExpense = createAsyncThunk(
    'expenses/deleteExpense',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/expense/expense/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const getExpense = createAsyncThunk(
    'expenses/getExpense',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/expense/expense/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const updateExpense = createAsyncThunk(
    'expenses/updateExpense',
    async ({ id, date, number, client, typeOfPayment, comment, company_id, tableData }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/expense/expense/${id}`, { date, number, client, typeOfPayment, comment, company_id, tableData });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const getExpensesData = createAsyncThunk(
    'expenses/getExpensesData',
    async ({ company_id }, { rejectWithValue }) => {
        try {
            const response = await api.get(`/expense/expense/data/${company_id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

// Define the slice
const expensesSlice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(addExpense.pending, (state) => {
                state.loading = true;
            })
            .addCase(addExpense.fulfilled, (state, action) => {
                // state.expenses = action.payload;
                state.loading = false;
            })
            .addCase(addExpense.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getAllExpenses.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllExpenses.fulfilled, (state, action) => {
                state.expenses = action.payload.expenses;
                state.loading = false;
            })
            .addCase(getAllExpenses.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteExpense.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteExpense.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteExpense.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateExpense.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateExpense.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateExpense.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getExpense.pending, (state) => {
                state.loading = true;
            })
            .addCase(getExpense.fulfilled, (state, action) => {
                state.loading = false;
                state.singleExpense = action.payload.expense;
            })
            .addCase(getExpense.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getExpensesData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getExpensesData.fulfilled, (state, action) => {
                state.loading = false;
                state.expensesData = action.payload.expenseData;
            })
            .addCase(getExpensesData.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});

// Export actions
export default expensesSlice.reducer;
